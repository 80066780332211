export default {
  setArchiveCategories: (state, categories) => {
    state.archives.categories = categories
  },
  setArchiveView: (state, view) => {
    state.archives.view = view
  },
  setArchiveSearch: (state, obj) => {
    for (const field in obj) state.archives.search[field] = obj[field]
  },
  setArchivePagination: (state, { page, itemsPerPage, sortBy, sortDesc }) => {
    if (page) state.archives.pagination.page = page
    if (itemsPerPage) state.archives.pagination.itemsPerPage = itemsPerPage
    if (sortBy) state.archives.pagination.sortBy = sortBy
    if (sortDesc) state.archives.pagination.sortDesc = sortDesc
  },
  setCarrelTerm: (state, term) => {
    state.carrel.term = term
  }
}
