export default {
  async loadTerms () {
    // Populate the termOptions with all of the terms in the calendar/classes service
    const { data } = await this.$feathers.service('calendar/classes').find({ query: { $distinct: 'term' } })
    let termCodes = []
    for (let i = 0; i < data.length; i++) {
      termCodes.push(data[i]._id)
    }
    const { data: terms } = await this.$feathers.service('system/term').find({ query: { term: { $in: termCodes }, $sort: { start: -1 }, $limit: 50 } })
    this.commit('calendar/setTerms', terms)
  }
}
