// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

// Routes
import paths from './paths'

function route (path, view, name) {
  return {
    name: name || view,
    path,
    component: (resolve) => import(/* webpackChunkName: "views/[request]" */ '@/views/' + view + '.vue').then(resolve)
  }
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths.map(path => route(path.path, path.view, path.name)).concat([
    { path: '*', redirect: '/' }
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (store.state.loggedIn) {
    store.commit('updateActivity')
  }
  if (from.path === '/') {
    const redirectPath = window.localStorage.getItem('redirectPath')
    if (redirectPath != null && redirectPath !== to.path) {
      window.localStorage.removeItem('redirectPath')
      next(redirectPath)
      return
    }
  }
  store.dispatch('main/usageLogUpdate', from)
  store.dispatch('main/usageLogAdd', to)

  const rec = paths.find(({ name }) => name === to.matched[0].name)
  if (rec) {
    const { title, loginRequired, roles } = rec
    if (!store.state.loggedIn && loginRequired) {
      // console.log(loginRequired)
      // console.log(store.state.loggedIn)
      window.localStorage.setItem('redirectPath', to.path)
      const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
      window.location = basePath + '/saml'
    }
    if (Array.isArray(roles) && roles.length > 0) {
      const hasRoles = store.state.user.roles.filter((role) => roles.includes(role))
      if (hasRoles.length === 0) {
        store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 10000, text: 'You do not have permission to access ' + title })
        if (from.path === '/') return
        next('/')
      }
    }
    document.title = title || 'Scots Portal'
    next()
  } else {
    next()
  }
})

export default router
