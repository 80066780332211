import { courseList } from '@/components/student/capstone'
export default {
  async getTerm (context) {
    const { data } = await this.$feathers.service('system/term').find({ query: { type: 'Traditional', end: { $gte: new Date() }, $sort: { start: 1 } } })
    if (data.length > 0) {
      return data[0].term
    }
  },
  async checkCapstoneCourse (context) {
    // First get the current term
    const term = await context.dispatch('getTerm')
    // Check for whether they are registered for a capstone course
    const pidm = context.rootState.user.spoof.pidm || context.rootState.user.pidm
    const { data: courseData } = await this.$feathers.service('calendar/classes').find({ query: { term, 'students.pidm': pidm } })
    return courseData.filter(({ title }) => courseList.includes(title.substring(0, 6)))
  }
}
