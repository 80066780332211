export default {
  timeclockFilter: {
    timeclocks: [],
    student: null,
    dates: []
  },
  timeclockDuration: 'Today',
  timeclocks: [],
  adminView: 'work',
  aidy: ''
}
