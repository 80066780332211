export default {
  setAccessToken: (state, token) => {
    state.accessToken = token
  },
  setSelectedTerm: (state, { term, id }) => {
    state.selectedTerm = term
    state.selectedId = id
  },
  setUser: (state, { _id, college, foundation }) => {
    if (_id != null) state.accessToken = _id
    if (college != null) state.college = college
    if (foundation != null) state.foundation = foundation
  }
}
