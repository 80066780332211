export default {
  setAdminFormSelectOptions (state, arr) {
    state.adminFormSelectOptions = arr
  },
  setAdminFormRadioOptions (state, arr) {
    state.adminFormRadioOptions = arr
  },
  setRequiresLogin (state, { loginRequired, form, name }) {
    state.loginRequired = loginRequired
    if (form) state.formLoginCheck = form
    if (name) state.formLoginName = name
  },
  setFormSettings (state, settings) {
    if ('id' in settings) {
      state.formSettings = settings
    }
  },
  setSubmissionId (state, id) {
    state.submissionId = id
  },
  setIsUploading (state, isUploading) {
    state.isUploading = isUploading
  },
  updatePayment (state, { department, foapal, total, items }) {
    if (department) state.payment.department = department
    if (foapal) state.payment.foapal = foapal
    if (total) state.payment.total = total
    if (items) state.payment.items = items
  },
  setAdminTab (state, tab) {
    state.admin.activeTab = tab
  },
  setAdminListFilters (state, obj) {
    for (const field in state.admin.list.filters) {
      if (field in obj) state.admin.list.filters[field] = obj[field]
    }
  },
  setAdminListPagination (state, pagination) {
    state.admin.list.pagination = pagination
  },
  setAdminSubmissionFilters (state, filters) {
    state.admin.submission.filters = filters
  },
  updateAdminSubmissionFilters (state, filters) {
    for (let l in filters) {
      state.admin.submission.filters[l] = filters[l]
    }
  },
  updateAdminSubmissionFilterFields (state, { index, value }) {
    state.admin.submission.filters.fields[index].value = value
  },
  setAdminSubmissionIncludedFields (state, includeFields) {
    state.admin.submission.includeFields = includeFields
  },
  setAdminSubmissionPagination (state, pagination) {
    state.admin.submission.pagination = pagination
  },
  setAdminSubmissionCount (state, count) {
    state.admin.submission.count = count
  },
  setAdminSubmissionIndex (state, index) {
    state.admin.submission.index = index
  },
  incrementAdminSubmissionIndex (state) {
    state.admin.submission.index++
  },
  setAdminOptionPagination (state, { page, itemsPerPage, sortBy, sortDesc }) {
    state.admin.options.pagination.page = page
    state.admin.options.pagination.itemsPerPage = itemsPerPage
    state.admin.options.pagination.sortBy = sortBy
    state.admin.options.pagination.sortDesc = sortDesc
  },
  setAdminOptionFilters (state, { name, active, createdBy, editedBy, editedDate, listType }) {
    if (name) state.admin.options.filters.name = name
    if (active != null) {
      if (active === '') state.admin.options.filters.active = null
      else state.admin.options.filters.active = active
    }
    if (createdBy) state.admin.options.filters.createdBy = createdBy
    if (editedBy) state.admin.options.filters.editedBy = editedBy
    if (editedDate) state.admin.options.filters.editedDate = editedDate
    if (listType != null) state.admin.options.filters.listType = listType
  }
}
