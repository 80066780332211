export default {
  snackbar (context, { active, color, timeout, text, actionText, action }) {
    let snackbar = Object.assign({}, context.snackbar)
    if (color) snackbar.color = color
    if (timeout) snackbar.timeout = (timeout === 0 ? -1 : timeout)
    else if (color === 'error') snackbar.timeout = 10000
    else if (color === 'success') snackbar.timeout = 6000
    if (text) snackbar.text = text
    if (actionText) snackbar.actionText = actionText
    if (action) snackbar.action = action
    // This should equate to a true or false exactly; using !! will turn something that evaluates to true/false but is not actually true/false into a true/false
    snackbar.active = (active == null ? true : !!active)

    context.commit('setSnackbar', snackbar)
  },
  usageLogAdd (context, { name, params }) {
    const user = context.rootState.user
    const arr = []
    for (const key in params) {
      arr.push({ key, value: params[key] })
    }
    let userId = user._id || '667428d55d4cbe3cf7b31557'
    this.$feathers.service('system/usage-log').create({ userId, name, params: arr, in: new Date() }).catch((e) => {
      console.log(e)
    }).then(({ _id }) => {
      context.commit('setUsageLogId', _id)
    })
  },
  async usageLogUpdate (context) {
    // When a usage log is added, we store that ID in the store. If that is populated and the out is blank for that id, then update it
    const id = context.state.usageLogId
    // Also, if the username is not populated (meaning they have been logged out) don't do anything (since we won't know which user's entry to update)
    const { username } = context.rootState.user
    if (id != null && id !== '' && username != null) {
      // Otherwise we need to update the "out" time for the page they came from. This will let us know how long they were on that page.
      // Note: the server will check to make sure we are not updating a record that was already updated
      await this.$feathers.service('system/usage-log').patch(id, { out: new Date() })
    }
  }
}
