export default {
  archives: {
    categories: [],
    view: 'table',
    search: {
      category: '',
      text: '',
      field: 'title',
      capstoneMajor: [],
      year: []
    },
    pagination: {
      page: 1,
      itemsPerPage: 12,
      sortBy: ['date'],
      sortDesc: [true]
    }
  },
  carrel: {
    term: ''
  }
}
