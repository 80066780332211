export default {
  setScheduleTab: (state, tab) => {
    state.scheduleTab = tab
  },
  setScheduleTermOptions: (state, options) => {
    state.scheduleTermOptions = options
  },
  setScheduleFilterField: (state, { future, term, speaker }) => {
    if (future != null) state.scheduleFilter.future = future
    if (term != null) state.scheduleFilter.term = term
    if (speaker != null) state.scheduleFilter.speaker = speaker
  },
  setScheduleShowStats: (state, show) => {
    state.scheduleShowStats = show
  },
  setScheduleResultOptions: (state, options) => {
    state.scheduleResultOptions = options
  },
  setCalendarMonth: (state, month) => {
    state.calendarMonth = month
  },
  setCalendarYear: (state, year) => {
    state.calendarYear = year
  },
  setStudentTerm: (state, term) => {
    state.studentTerm = term
  },
  setStudentTermOptions: (state, options) => {
    state.studentTermOptions = options
  },
  setHallOptions: (state, options) => {
    state.hallOptions = options
  },
  setStudentFilterField: (state, { field, filter }) => {
    state.studentFilter[field] = filter
  },
  setStudentResultOptions: (state, options) => {
    state.studentResultOptions = options
  }
}
