export default {
  buildNotificationQuery (context, { select }) {
    // console.log(select)
    const query = { $limit: 0, 'user.pidm': { $exists: true } }
    for (const field in select) {
      const val = select[field]
      if (val == null || val === '') continue
      const dt = new Date()
      switch (field) {
        case 'notifications':
          query['notifications.' + val] = { $exists: false }
          if (val > 0) {
            query['notifications.' + val - 1] = { $exists: true }
          }
          break
        case 'age':
          dt.setDate(dt.getDate() - val)
          query.history = { $elemMatch: { status: 'Received', date: { $lte: dt } } }
          break
        case 'person':
          if (Array.isArray(val)) {
            if (val.length > 0) {
              query['user.person'] = { $in: val }
            }
          } else if (val !== '') {
            query['user.person'] = val
          }
          break
        default:
          if (Array.isArray(val)) {
            query[field] = { $in: val }
          } else {
            query[field] = val
          }
      }
    }
    // console.log(query)
    return query
  },
  async getNotificationUsers (context, { select }) {
    const query = await context.dispatch('buildNotificationQuery', { select })
    const { total } = await this.$feathers.service('mailroom/package').find({ query })
    context.commit('setNotificationSentTotal', total)
    const users = {}
    let userCount = 0
    query.$limit = 20
    for (let i = 0; i < total; i += 20) {
      query.$skip = i
      const { data } = await this.$feathers.service('mailroom/package').find({ query })
      for (const { _id, user: { pidm } } of data) {
        if (!(pidm in users)) {
          users[pidm] = []
          userCount++
        }
        users[pidm].push(_id)
      }
    }
    context.commit('setNotificationSentUsers', userCount)
    // console.log(users)
    return users
  },
  async sendNotifications (context, { notificationId }) {
    const notice = await this.$feathers.service('mailroom/notification').get(notificationId)
    const query = await context.dispatch('buildNotificationQuery', notice)
    // console.log(query)
    const { total } = await this.$feathers.service('mailroom/package').find({ query })
    context.commit('setNotificationSentTotal', total)
    const users = {}
    let userCount = 0
    query.$limit = 20
    for (let i = 0; i < total; i += 20) {
      query.$skip = i
      const { data } = await this.$feathers.service('mailroom/package').find({ query })
      for (const { _id, user: { pidm } } of data) {
        if (!(pidm in users)) {
          users[pidm] = []
          userCount++
        }
        users[pidm].push(_id)
      }
    }
    context.commit('setNotificationSentUsers', userCount)
    // console.log(users)
    for (const pidm in users) {
      const ids = users[pidm]
      const to = []
      if ('email' in notice && 'include' in notice.email && notice.email.include === true) {
        // load the email address and name from the directory
        const { data: dirData } = await this.$feathers.service('directory/people').find({ query: { pidm, $limit: 1 } })
        if (dirData.length === 1) {
          const { email, name: { first, last } } = dirData[0]
          let subject = notice.email.subject.replace('{Package_Count}', ids.length).replace('{First_Name}', first).replace('{Last_Name}', last)
          let html = notice.email.body.replace('{Package_Count}', ids.length).replace('{First_Name}', first).replace('{Last_Name}', last)
          // send email
          await this.$feathers.service('system/email').create({
            from: 'mailroom@covenant.edu',
            to: email,
            // to: 'jon.moon@covenant.edu',
            subject,
            html
          })
          // console.log({ from: 'mailroom@covenant.edu', to: email, subject, html })
          to.push(email)
        }
      }
      if ('text' in notice && 'include' in notice.text && notice.text.include === true) {
        // load the phone number from the preferences (if it exists)
        const { data } = await this.$feathers.service('mailroom/preferences').find({ query: { 'user.pidm': pidm, phone: { $exists: true }, $limit: 1 } })
        if (data.length === 1 && 'phone' in data[0] && typeof (data[0].phone) === 'string') {
          const phone = data[0].phone.replace(/[^0-9]/g, '')
          if (phone.length !== 10) continue
          // send the text
          let body = notice.text.message.replace('{Package_Count}', ids.length)
          await this.$feathers.service('system/text').create({
            from: '+17064507015',
            to: '+1' + phone,
            body
          })
          // console.log({ from: '+17064507015', to: '+1' + phone, body })
          to.push(phone)
        }
      }
      // for each package, add the notification to the history and check to see if any changes should be made after the notification was sent (stored in the 'after' subdocument)
      for (const id of ids) {
        const obj = { $push: { notifications: { id: notificationId, to, date: new Date() } } }
        if ('after' in notice && notice.after != null) {
          for (const l in notice.after) {
            switch (l) {
              case 'status':
                obj.status = notice.after[l]
                break
            }
          }
        }
        await this.$feathers.service('mailroom/package').patch(id, obj)
        // console.log('would be patching ' + id, obj)
        context.commit('incrementNotificationsSent')
      }
    }
  },
  async sendNotification (context, { notificationId, pidm, ids, email, text, after }) {
    const to = []
    if (email && 'include' in email && email.include === true) {
      // load the email address and name from the directory
      const { data: dirData } = await this.$feathers.service('directory/people').find({ query: { pidm, $limit: 1 } })
      if (dirData.length === 1) {
        const { email: toEmail, name: { first, last } } = dirData[0]
        let subject = email.subject.replace('{Package_Count}', ids.length).replace('{First_Name}', first).replace('{Last_Name}', last)
        let html = email.body.replace('{Package_Count}', ids.length).replace('{First_Name}', first).replace('{Last_Name}', last)
        // send email
        await this.$feathers.service('system/email').create({
          from: 'mailroom@covenant.edu',
          to: toEmail,
          subject,
          html
        })
        // console.log({ from: 'mailroom@covenant.edu', to: toEmail, subject, html })
        to.push(toEmail)
      }
    }
    if (text && 'include' in text && text.include === true) {
      // load the phone number from the preferences (if it exists)
      const { data } = await this.$feathers.service('mailroom/preferences').find({ query: { 'user.pidm': pidm, phone: { $exists: true, $ne: null }, $limit: 1 } })
      if (data.length === 1 && 'phone' in data[0] && typeof (data[0].phone) === 'string') {
        const phone = data[0].phone.replace(/[^0-9]/g, '')
        if (phone.length === 10) {
          // send the text
          let body = text.message.replace('{Package_Count}', ids.length)
          await this.$feathers.service('system/text').create({
            from: '+17064507015',
            to: '+1' + phone,
            body
          })
          // console.log({ from: '+17064507015', to: '+1' + phone, body })
          to.push(phone)
        }
      }
    }
    // for each package, add the notification to the history and check to see if any changes should be made after the notification was sent (stored in the 'after' subdocument)
    for (const id of ids) {
      const obj = { $push: { notifications: { id: notificationId, to, date: new Date() } } }
      if (after) {
        for (const l in after) {
          switch (l) {
            case 'status':
              obj.status = after[l]
              break
          }
        }
      }
      // console.log('patching ' + id, obj)
      await this.$feathers.service('mailroom/package').patch(id, obj)
      context.commit('incrementNotificationsSent')
    }
  }
}
