export default {
  async loadMusicAdminTerms (context) {
    if (context.state.music.admin.terms.length > 0) return
    const aggregate = [
      { $match: {
        term: { $regex: '05$|60$' },
        $or: [
          { 'schedule.title': { $regex: 'MUS[1-4]0[1-8]' } },
          { 'academics.major': 'MUS' },
          { 'academics.minor': 'MUS' }
        ]
      } },
      { $group: {
        _id: '$term',
        majors: { $sum: { $cond: [{ $in: ['MUS', '$academics.major'] }, 1, 0] } },
        minors: { $sum: { $cond: [{ $in: ['MUS', '$academics.minor'] }, 1, 0] } },
        other: { $sum: { $cond: [
          {
            $and: [
              { $not: { $in: ['MUS', '$academics.major'] } },
              { $not: { $in: ['MUS', '$academics.minor'] } }
            ]
          }, 1, 0
        ] } }
      } },
      { $sort: { _id: -1 } }
    ]
    const { data } = await this.$feathers.service('student/term-detail').find({ query: { aggregate } })
    const terms = data.map(({ _id: value }) => {
      const text = (value.substring(4) === '05' ? 'Spring' : 'Fall') + ' ' + value.substring(0, 4)
      return { text, value }
    })
    const rec = terms.find(({ value }) => value === context.state.music.admin.term)
    if (!rec) context.commit('setMusicAdmin', { term: terms[0].value, terms })
    else context.commit('setMusicAdmin', { terms })
  }
}
