import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import vuetify from './plugins/vuetify'
import { $feathers } from './plugins/feathers'
import './plugins/fusioncharts'
import './plugins/tiptap'
import './plugins/input-facade'
import { Chart, registerables } from 'chart.js'
import VueYouTubeEmbed from 'vue-youtube-embed'

Vue.use(VueCompositionApi)
Vue.use(VueYouTubeEmbed)
Vue.config.productionTip = false

Chart.register(...registerables)

// Auth first before loading the app
$feathers.reAuthenticate()
  .catch((e) => {
    // console.log(e)
  })
  .then(response => {
    if ('lastActivity' in window.localStorage && window.localStorage.lastActivity != null) {
      const lastActive = window.localStorage.lastActivity
      const now = new Date().getTime()
      // Get the difference in minutes
      const diff = (now - lastActive) / 60000
      if (diff > 15) {
        store.dispatch('logout')
        return $feathers.logout()
      }
    }
    // console.log('response', response)
    if (typeof (response) !== 'undefined' && 'user' in response) {
      // console.log('response.user', response.user)
      return $feathers.service('users').get(response.user._id)
    }
  })
  .then(async (user) => {
    if (typeof (user) !== 'undefined') {
      $feathers.set('user', user)
      await store.dispatch('login', user)
    }
  })
  // Render the app
  .then(() => {
    // eslint-disable-next-line no-new
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })
