/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

// Store functionality
import modules from './modules'

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  modules,
  state: {
    loggedIn: false,
    loggedOut: false,
    user: { },
    settings: {
      darkMode: false,
      miniMenu: false
    },
    sideNavActive: false,
    roles: {},
    lastActivity: null,
    referrer: '',
    selectedTerm: '',
    selectedTermLabel: '',
    loginRedirectUrl: ''
  },
  mutations: {
    updateActivity: (state) => {
      state.lastActivity = new Date().getTime()
      window.localStorage.lastActivity = state.lastActivity
    },
    clearActivity (state) {
      state.lastActivity = null
      window.localStorage.lastActivity = null
    },
    setUser (state, user) {
      state.user = user
    },
    updateSettings (state, settings) {
      state.settings = settings
    },
    updateSetting (state, { field, value }) {
      state.settings[field] = value
    },
    setSideNavActive (state, sideNavActive) {
      state.sideNavActive = sideNavActive
    },
    setRoles (state, roles) {
      // Convert the roles array into an object and save in the store
      let obj = {}
      for (let i = 0; i < roles.length; i++) {
        obj[roles[i]] = true
      }
      state.roles = obj
    },
    setLoggedIn (state, loggedIn) {
      state.loggedIn = loggedIn
    },
    setLoggedOut (state, loggedOut) {
      state.loggedOut = loggedOut
    },
    setReferrer (state, referrer) {
      state.referrer = referrer
    },
    setTerm (state, { term, label }) {
      state.selectedTerm = term
      state.selectedTermLabel = label
    },
    setLoginRedirectUrl (state, url) {
      state.loginRedirectUrl = url
    }
  },
  getters: {
    getUser: (state) => () => {
      return state.user.spoof || state.user
    },
    hasRole: (state) => (role) => {
      if (typeof (role) === 'string') {
        return (role in state.roles)
      } else if (typeof (role) === 'object' && 'length' in role) {
        for (let j = 0; j < role.length; j++) {
          if (role[j] in state.roles) return true
        }
      }
      return false
    }
  },
  actions: {
    login (context, user) {
      if (user && 'username' in user) {
        context.commit('setLoggedIn', true)
        context.commit('setLoggedOut', false)
        context.commit('setUser', user)
        context.commit('setRoles', 'spoof' in user ? user.spoof.roles : user.roles || [])
        if ('settings' in user) {
          for (let field in user.settings) {
            context.commit('updateSetting', { field, value: user.settings[field] })
          }
        }
        context.commit('updateActivity')
      }
    },
    logout (context) {
      context.commit('setLoggedIn', false)
      context.commit('setLoggedOut', true)
      context.commit('setUser', {})
      context.commit('setRoles', [])
      context.commit('clearActivity')
      context.commit('updateSetting', { field: 'darkMode', value: false })
    },
    updateUser (context, user) {
      if ('_id' in context.state.user) {
        // Update the user in the database and in the vuex store once complete
        this.$feathers.service('users').update(context.state.user._id, user).then((data) => {
          context.commit('setUser', user)
        })
      } else {
        context.commit('setUser', user)
      }
    },
    updateUserSetting (context, { field, value }) {
      context.commit('updateSetting', { field, value })
      let settings = context.state.settings
      settings[field] = value
      let user = context.state.user
      user.settings = settings
      context.dispatch('updateUser', user)
    },
    toggleDrawer (context) {
      context.dispatch('setsideNavActive', !context.state.sideNavActive)
    }
  }
})

export default store
