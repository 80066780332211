export default {
  setTimeclockFilter: (state, filter) => {
    state.timeclockFilter = filter
  },
  setTimeclockDuration: (state, duration) => {
    state.timeclockDuration = duration
  },
  setTimeclocks: (state, list) => {
    state.timeclocks = list
  }
}
