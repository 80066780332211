import Vue from 'vue'
import Vuex from 'vuex'
import feathers from '@feathersjs/client'
import io from 'socket.io-client'
import store from '../store'

const apiURL = process.env.VUE_APP_API_URL

const socket = io(apiURL, { path: process.env.VUE_APP_API_PATH, transports: ['websocket'] })

const $feathers = feathers()
  .configure(feathers.socketio(socket, { timeout: 20000 }))
  .configure(feathers.authentication({ storage: window.localStorage }))

Vue.prototype.$feathers = $feathers
Vuex.Store.prototype.$feathers = $feathers

let timeout = null
socket.on('connect', () => {
  if (timeout != null) clearTimeout(timeout)
  timeout = null
  store.commit('main/setOffline', false)
})
socket.on('disconnect', () => {
  timeout = setTimeout(() => store.commit('main/setOffline', true), 5000)
})

export {
  $feathers
}
