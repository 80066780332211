export default {
  classLists: [],
  myLists: [],
  sharedLists: [],
  workerLists: [],
  athleticLists: [],
  filter: {
    text: '',
    field: 'name',
    types: [],
    options: [],
    active: false
  },
  listView: 'list',
  show: {
    dep: true,
    phone: false,
    hall: false,
    office: false,
    box: true,
    job: true
  },
  listGroup: undefined,
  listGroupIncludeBlanks: false,
  currentResults: [],
  resultCount: 0,
  resultOptions: {
    sortBy: [ 'name.last' ],
    sortDesc: [ 0 ],
    page: 1,
    itemsPerPage: 12
  }
}
