import Vue from 'vue'
import InputFacade from 'vue-input-facade'

// migrating from v-mask
const options = {
  // rename the directive from: v-facade to: v-mask
  name: 'mask',

  // use these tokens instead of the default
  tokens: {
    '#': { pattern: /\d/ },
    X: { pattern: /[0-9a-z]/i },
    S: { pattern: /[a-z]/i },
    A: { pattern: /[a-z]/i, transform: (v) => v.toLocaleUpperCase() },
    a: { pattern: /[a-z]/i, transform: (v) => v.toLocaleLowerCase() },
    H: { pattern: /[0-9a-f]/i, transform: (v) => v.toLocaleUpperCase() },
    h: { pattern: /[0-9a-f]/i, transform: (v) => v.toLocaleLowerCase() },
    '*': { pattern: /[*]/i },
    '\\': { escape: true }
  }
}

Vue.use(InputFacade, options)
