export default {
  hasMenu: false,
  snackbar: {
    active: false,
    color: '',
    timeout: 3000,
    text: '',
    action: '',
    actionText: ''
  },
  offline: true,
  pageSubTitle: '',
  usageLogId: ''
}
