export default {
  scheduleTab: 0,
  scheduleTermOptions: [],
  scheduleFilter: {
    future: true,
    term: '',
    speaker: ''
  },
  scheduleShowStats: false,
  scheduleResultOptions: {
    sortBy: [ 'date' ],
    sortDesc: [ 0 ],
    page: 1,
    itemsPerPage: 10
  },
  calendarMonth: 0,
  calendarYear: 0,
  studentTerm: '',
  studentTermOptions: [],
  hallOptions: [],
  studentFilter: {
    name: '',
    hall: [],
    classLevel: [],
    sport: [],
    gender: '',
    remainingMin: '',
    remainingMax: '',
    fullPartTime: ''
  },
  studentResultOptions: {
    sortBy: [ 'name.last', 'name.preferred' ],
    sortDesc: [ 0, 0 ],
    page: 1,
    itemsPerPage: 10
  }
}
