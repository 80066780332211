export default {
  adminFormSelectOptions: [],
  adminFormRadioOptions: [],
  loginRequired: false,
  formLoginCheck: '',
  formLoginName: '',
  optionList: {
    page: 1,
    itemsPerPage: 10
  },
  formSettings: {
    id: '',
    asteriskOnRequired: false
  },
  submissionId: '',
  isUploading: false,
  payment: {
    department: '',
    foapal: {
      fund: '',
      org: '',
      acct: '',
      prog: ''
    },
    total: 0,
    items: []
  },
  admin: {
    activeTab: 1,
    list: {
      filters: {
        name: '',
        group: [],
        status: ''
      },
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false]
      }
    },
    submission: {
      filters: {
        status: ['Pending', 'Submitted'],
        dateStart: null,
        dateEnd: null,
        revision: '',
        form: '',
        fields: []
      },
      includeFields: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['submittedDate'],
        sortDesc: [true]
      },
      count: 0,
      index: 0
    },
    options: {
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false]
      },
      filters: {
        name: '',
        active: true,
        createdBy: '',
        editedBy: '',
        editedDate: '',
        listType: ''
      }
    }
  }
}
