export default {
  loadFieldOptions (context, { field }) {
    context.commit('setFilter', { options: [] })
    this.$feathers.service('directory/people').find({ query: { $distinct: field } }).then(({ data }) => {
      let options = []
      for (let i = 0; i < data.length; i++) {
        options.push(data[i]._id)
      }
      context.commit('setFilter', { options })
    })
  }
}
