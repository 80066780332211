export default {
  setTermId: (state, termId) => {
    state.termId = termId
  },
  setTerm: (state, term) => {
    state.term = term
  },
  setConfirmationId: (state, id) => {
    state.confirmationId = id
  },
  setHasCapstone: (state, val) => {
    state.hasCapstone = val
  },
  setScheduleAdminFields: (state, obj) => {
    if ('searchText' in obj) state.scheduleAdmin.searchText = obj.searchText
    if ('term' in obj) state.scheduleAdmin.term = obj.term
    if ('filter' in obj) state.scheduleAdmin.filter = obj.filter
    if ('options' in obj) state.scheduleAdmin.options = obj.options
  },
  setProgramAdminFields: (state, obj) => {
    if ('searchText' in obj) state.programAdmin.searchText = obj.searchText
    if ('term' in obj) state.programAdmin.term = obj.term
    if ('filter' in obj) state.programAdmin.filter = obj.filter
    if ('options' in obj) state.programAdmin.options = obj.options
  },
  setStudyAbroadFilter: (state, obj) => {
    state.studyAbroad.filter = { ...state.studyAbroad.filter, ...obj }
  },
  setStudyAbroadShowFilter: (state, show) => {
    state.studyAbroad.showFilter = show
  },
  setMusicAdmin: (state, obj) => {
    for (const key in obj) {
      if (key in state.music.admin) state.music.admin[key] = obj[key]
    }
  }
}
