export default {
  setHasMenu: (state, hasMenu) => {
    state.hasMenu = hasMenu
  },
  setSnackbar: (state, snackbar) => {
    state.snackbar = snackbar
  },
  setOffline: (state, offline) => {
    state.offline = offline
  },
  setPageSubTitle: (state, subtitle) => {
    state.pageSubTitle = subtitle
  },
  setUsageLogId: (state, id) => {
    state.usageLogId = id
  }
}
