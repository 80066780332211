export default {
  setTerm: (state, term) => {
    state.term = term
  },
  setTermText: (state, text) => {
    state.termText = text
  },
  setTerms: (state, terms) => {
    state.terms = terms
    if (state.term === '') {
      state.term = terms[0].term
      state.termText = terms[0].label
    }
  },
  setStartHour: (state, val) => {
    state.startHour = val
  },
  setEndHour: (state, val) => {
    state.endHour = val
  },
  setCollapsed: (state, val) => {
    state.collapsed = val
  },
  setThin: (state, val) => {
    state.thin = val
  }
}
