export default {
  loadAdminFormOptions (context) {
    this.$feathers.service('forms/select-options').find({ query: { $sort: { name: 1 } } }).then((data) => {
      this.commit('setAdminFormSelectOptions', data)
      let arr = []
      data.forEach(({ text, value, count }) => { if (count < 5) arr.push({ text, value }) })
      context.commit('setAdminFormRadioOptions', arr)
    })
  },
  async adminLoadSubmissions (context, { formId }) {
    let { filters, pagination } = context.state.admin.submission
    let query = buildQuery(formId, filters, pagination)

    try {
      let { data, total } = await this.$feathers.service('forms/submission').find({ query })
      context.state.admin.submission.count = total
      return data
    } catch (err) {
      throw err
    }
  },
  // Loads and returns all submissions based on the current filters (ignores limit/skip from pagination, but keeps sorting)
  async adminLoadAllSubmissions (context, { formId }) {
    let { filters, pagination } = context.state.admin.submission
    let query = buildQuery(formId, filters, pagination)
    query.$limit = 0
    query.$skip = 0

    const service = this.$feathers.service('forms/submission')
    let { total } = await service.find({ query })
    let arr = []
    query.$limit = 20
    for (let i = 0; i < total; i += 20) {
      query.$skip = i
      let { data } = await service.find({ query })
      for (let j = 0; j < data.length; j++) {
        arr.push(data[j])
      }
    }

    return arr
  },
  adminLoadIndexedSubmissionId (context, { formId, increment }) {
    let { filters, pagination, index } = context.state.admin.submission
    let query = buildQuery(formId, filters, pagination)
    query.$select = ['_id']
    query.$skip = index + increment
    query.$limit = 1

    return new Promise((resolve, reject) => {
      this.$feathers.service('forms/submission').find({ query }).then(({ data, total }) => {
        resolve(data[0]._id)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

function buildQuery (formId, filters, pagination) {
  let { status, dateStart, dateEnd, revision, fields } = filters
  let query = { 'form.base': formId, $populate: 'user' }
  if (revision && revision !== '') query['form.revision'] = revision
  if (status && status.length > 0) query['status'] = { $in: status }
  if (dateStart && dateEnd) {
    query['submittedDate'] = { $gte: dateStart, $lte: dateEnd }
  } else if (dateStart) {
    query['submittedDate'] = { $gte: dateStart }
  } else if (dateEnd) {
    query['submittedDate'] = { $lte: dateEnd }
  }
  if (fields.length > 0) {
    for (const { field, value } of fields) {
      if (field === 'User Name') {
        query.name = { $regex: value, $options: 'i' }
      } else {
        query['dataArr'] = { $elemMatch: { field, value: { $regex: value, $options: 'i' } } }
      }
    }
  }
  let { page, itemsPerPage, sortBy, sortDesc } = pagination
  if (itemsPerPage) {
    query.$limit = itemsPerPage
    if (page) query.$skip = (page - 1) * itemsPerPage
  }
  if (Array.isArray(sortBy) && sortBy.length > 0) {
    query.$sort = {}
    for (let i = 0; i < sortBy.length; i++) {
      query.$sort[sortBy[i]] = sortDesc[i] ? 1 : -1
    }
  }
  return query
}
