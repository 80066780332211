<template>
  <v-app>
    <core-offline v-if="offline"></core-offline>
    <template v-else>
      <core-sidenav v-if="isLoggedIn || !requiresLogin" :drawerActive="drawerActive" class="no-print"></core-sidenav>
      <core-header v-if="isLoggedIn || !requiresLogin" @toggleDrawer="drawerActive=!drawerActive" class="no-print"></core-header>
      <core-login v-if="!isLoggedIn && requiresLogin" :messages="messages" @loginSuccess="resetLoginTimer"></core-login>
      <core-view v-if="isLoggedIn || !requiresLogin" :drawer-active="drawerActive"></core-view>
    </template>
    <v-img v-if="!isLoggedIn && requiresLogin" :src="imageSource" width="100%" height="100%">
      <div class='footer'>
          This site is reserved for use by Covenant College employees, students, and other designated individuals. Use of this resource without authority, or in excess of your authority, is strictly prohibited. Continued use of this site constitutes agreement with the terms of usage outlined by Covenant College.
        </div>
    </v-img>
  </v-app>
</template>
<style>
  .v-snack--top {
    top: 70px;
  }
  .dark-background {
    width: 100%;
    height: 100%;
    background-color: #193264;
    opacity: .4;
  }
  .footer {
    position: fixed;
    bottom: 0;
    background-color: black;
    color: white;
    text-align: center;
    padding: 1em 3em;
    width: 100%;
    /* opacity: 1; */
    /* z-index: 100; */
  }
  .v-application.theme--dark .primary, .theme--dark.v-btn:not(.v-btn--outlined).primary, .theme--dark.v-btn:not(.v-btn--outlined).secondary, .theme--dark.v-btn:not(.v-btn--outlined).accent, .theme--dark.v-btn:not(.v-btn--outlined).success, .theme--dark.v-btn:not(.v-btn--outlined).error, .theme--dark.v-btn:not(.v-btn--outlined).warning, .theme--dark.v-btn:not(.v-btn--outlined).info {
    color: black !important;
  }
  @media print {
    .no-print {
      display: none !important;
    }
    main.v-main {
      padding: 0 !important;
    }
    div#formContainer div.row > div {
      page-break-inside: avoid;
    }
  }
  .mini-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 11px;
  }
  .mini-scrollbar::-webkit-scrollbar-button {
    width: 5px;
    height: 0px;
  }
  .mini-scrollbar::-webkit-scrollbar-corner {
    background: transparent;
  }
  .mini-scrollbar::-webkit-scrollbar-thumb {
    border: 0px solid transparent;
    border-radius: 50px;
  }
  .theme--light .mini-scrollbar::-webkit-scrollbar-thumb {
    background: linear-gradient(to top right, #193264, #003366);
  }
  .theme--dark .mini-scrollbar::-webkit-scrollbar-thumb {
    background: linear-gradient(to top right, #c0c0c0, #a0a0a0);
  }
  .mini-scrollbar::-webkit-scrollbar-track {
    border: 0px none;
    border-radius: 53px;
  }
  .v-btn--active.no-active::before {
    opacity: 0 !important
  }
  .v-application--wrap > .v-image.v-responsive > .v-image__image.v-image__image--cover {
    transition: background 1s linear;
  }
</style>
<script>
import { ref, computed, watch, onBeforeMount, onMounted } from '@vue/composition-api'
import paths from './router/paths'

export default {
  name: 'App',
  components: {
    CoreOffline: () => import('@/components/core/Offline'),
    CoreSidenav: () => import(/* webpackChunkName: "core" */ '@/components/core/Sidenav'),
    CoreHeader: () => import(/* webpackChunkName: "core" */ '@/components/core/Header'),
    CoreView: () => import(/* webpackChunkName: "core" */ '@/components/core/View'),
    CoreLogin: () => import(/* webpackChunkName: "core" */ '@/components/core/Logout')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    // root.$store.commit('setSideNavActive', (root.$vuetify.breakpoint.name === 'lg' || root.$vuetify.breakpoint.name === 'xl'))
    const drawerActive = ref(root.$vuetify.breakpoint.name === 'lg' || root.$vuetify.breakpoint.name === 'xl')
    const offline = computed(() => root.$store.state.main.offline)
    const isLoggedIn = computed(() => root.$store.state.loggedIn)
    const messages = ref([])

    const darkMode = computed(() => user.value && 'settings' in user.value && 'darkMode' in user.value.settings ? user.value.settings.darkMode : false)
    watch(darkMode, () => {
      root.$vuetify.theme.dark = darkMode.value
    })

    const formRequiresLogin = computed(() => root.$store.state.forms.loginRequired)
    const formLoginChecked = computed(() => root.$store.state.forms.formLoginCheck)
    const formLoginName = computed(() => root.$store.state.forms.formLoginName)

    const requiresLogin = computed(() => {
      messages.value = []
      if (root.$route.matched.length === 0) return true
      if (root.$route.matched.length > 0 && root.$route.matched[0].name === 'FormViewer') {
        // Check the form to see if it requires a login
        if (formRequiresLogin.value && (root.$route.params['form'] === formLoginChecked.value || root.$route.params['form'] === formLoginName.value)) {
          messages.value = ['You must log in to access the requested form.']
          return true
        }
        messages.value = []
        return false
      }
      const rec = paths.find(({ path }) => path === root.$route.matched[0].path)
      if (rec) {
        if ('loginRequired' in rec) return rec.loginRequired
        if ('roles' in rec && rec.roles.length > 0) return true
      }
      return true
    })

    const imageSource = ref('')
    function changeBackground () {
      const num = (Math.floor(Math.random() * 100) % 33)
      imageSource.value = require('@/assets/backgrounds/background' + (num < 10 ? '0' : '') + num + '.jpg')
    }
    changeBackground()
    setInterval(() => { changeBackground() }, 15000)

    if (isLoggedIn.value) root.$store.commit('updateActivity')
    root.$vuetify.theme.dark = darkMode.value || false

    function resetLoginTimer () {
      root.$store.commit('updateActivity')
    }

    onBeforeMount(() => {
      root.$store.commit('setReferrer', document.referrer)

      window.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          root.$store.dispatch('main/usageLogUpdate', root.$route)
        } else {
          root.$store.dispatch('main/usageLogAdd', root.$route)
        }
      })
    })

    onMounted(() => {
      window.addEventListener('keypress', () => {
        if (isLoggedIn.value) {
          // console.log('Extending session due to key press')
          resetLoginTimer()
        }
      })
      window.addEventListener('click', () => {
        if (isLoggedIn.value) {
          // console.log('Extending session due to mouse click')
          resetLoginTimer()
        }
      })
    })

    return {
      drawerActive,
      offline,
      imageSource,
      darkMode,
      isLoggedIn,
      messages,
      formRequiresLogin,
      formLoginChecked,
      requiresLogin,
      resetLoginTimer
    }
  }
}
</script>
