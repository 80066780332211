export default {
  setPackageTab: (state, tab) => {
    state.packageTab = tab
  },
  setPackageFilter: (state, filter) => {
    state.packageFilter = filter
  },
  patchPackageFilter: (state, option) => {
    for (let l in option) {
      if (l in state.packageFilter) state.packageFilter[l] = option[l]
    }
  },
  setPackagePagination: (state, options) => {
    state.packagePagination = options
  },
  setNotificationSentId: (state, id) => {
    state.sentNotifications.id = id
  },
  setNotificationSentTotal: (state, total) => {
    state.sentNotifications.total = total
  },
  setNotificationSentUsers: (state, userCount) => {
    state.sentNotifications.userCount = userCount
  },
  setNotificationsSent: (state, sent) => {
    state.sentNotifications.sent = sent
  },
  incrementNotificationsSent: (state, sent) => {
    state.sentNotifications.sent++
  }
}
