export default {
  setClassLists: (state, lists) => {
    state.classLists = lists
  },
  setMyLists: (state, lists) => {
    state.myLists = lists
  },
  setSharedLists: (state, lists) => {
    state.sharedLists = lists
  },
  setWorkerLists: (state, lists) => {
    state.workerLists = lists
  },
  setAthleticLists: (state, lists) => {
    state.athleticLists = lists
  },
  setFilter: (state, { text, field, types, options }) => {
    if (text != null) state.filter.text = text
    if (field != null) state.filter.field = field
    if (Array.isArray(types)) state.filter.types = types
    if (Array.isArray(options)) state.filter.options = options
  },
  toggleFilterActive: (state) => {
    state.filter.active = !state.filter.active
  },
  setResults: (state, results) => {
    state.currentResults = results.data
    state.resultCount = results.total
  },
  setResultOptions: (state, options) => {
    state.resultOptions = options
  },
  setResultOption: (state, { field, value }) => {
    state.resultOptions[field] = value
  },
  setView: (state, view) => {
    state.listView = view
  },
  setGroup: (state, group) => {
    state.listGroup = group
  },
  setGroupIncludeBlanks: (state, includeBlanks) => {
    state.listGroupIncludeBlanks = includeBlanks
  },
  setShow: (state, show) => {
    if ('dep' in show) state.show.dep = show.dep
    if ('phone' in show) state.show.phone = show.phone
    if ('hall' in show) state.show.hall = show.hall
    if ('office' in show) state.show.office = show.office
    if ('box' in show) state.show.box = show.box
    if ('job' in show) state.show.job = show.job
  }
}
