export default {
  async loadOptions (context) {
    try {
      const { data } = await this.$feathers.service('chapel/schedule').find({ query: { $distinct: 'term' } })
      let arr = []
      for (let i = 0; i < data.length; i++) {
        let value = data[i]._id
        let year = value.substring(0, 4)
        let term = value.substring(4, 6) === '05' ? 'Spring' : 'Fall'
        arr.push({ value, text: term + ' ' + year })
      }
      arr.sort((a, b) => {
        if (a.value < b.value) return 1
        return -1
      })
      context.commit('setScheduleTermOptions', arr)
      context.commit('setScheduleFilterField', { term: arr[0].value })
    } catch (e) {
      alert('Error loading terms: ' + e)
    }
    if ('username' in context.rootState.user) {
      try {
        const { data } = await this.$feathers.service('chapel/student').find({ query: { $distinct: 'term' } })
        let arr = []
        for (let i = 0; i < data.length; i++) {
          let value = data[i]._id
          let year = value.substr(0, 4)
          let springOrFall = value.substr(4) === '05' ? 'Spring ' : 'Fall '
          arr.push({ text: springOrFall + year, value })
        }
        arr.reverse()
        context.commit('setStudentTermOptions', arr)
        context.commit('setStudentTerm', arr[0].value)
      } catch (e) {
        alert('Error loading terms: ' + e)
      }
    }
    if ('Technology Services' in context.rootState.roles || 'Chapel Office' in context.rootState.roles) {
      const aggregate = [
        { $match: { 'housing.hall': { $ne: null } } },
        { $group: { _id: '$housing.hall', count: { $sum: 1 } } },
        { $project: { hall: '$_id', count: 1 } },
        { $sort: { hall: 1 } }
      ]
      try {
        const { data } = await this.$feathers.service('chapel/student').find({ query: { aggregate } })
        context.commit('setHallOptions', data.map(({ hall }) => { return { text: hall, value: hall } }))
      } catch (e) {
        alert('Error loading hall data: ' + e)
      }
    }
  }
}
