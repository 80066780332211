export default {
  async loadArchiveCategories (context) {
    const regex = /^the /i
    const { data } = await this.$feathers.service('library/archive/category').find({ query: { $limit: 50 } })
    data.sort((a, b) => {
      let aLabel = a.label
      let bLabel = b.label
      if (regex.test(aLabel)) aLabel = aLabel.substring(4)
      if (regex.test(bLabel)) bLabel = bLabel.substring(4)
      return aLabel < bLabel ? -1 : 1
    })
    context.commit('setArchiveCategories', data)
  }
}
