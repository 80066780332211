export default {
  packageTab: 0,
  packageFilter: {
    barcode: '',
    user: '',
    status: ['Received', 'Ready for Pickup'],
    location: []
  },
  packagePagination: {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['createdAt'],
    sortDesc: [true]
  },
  sentNotifications: {
    id: '',
    total: 0,
    userCount: 0,
    sent: 0
  }
}
